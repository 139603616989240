import { findLocationByPlaceId } from './geoLocator';
import { AutocompletePredictionRestrictionTypes } from './types';
import { createAutocompletionRequest, getAutoCompleteService } from './api/placePredictions';
import { CANADA_COUNTRY_FILTER, US_AND_CANADA_COUNTRY_FILTER } from './searchPredictions';

export async function getPositionFromDescription(areaName: string, useUsListings = false) {
  const placeIds = await querySinglePosition(areaName, useUsListings ? US_AND_CANADA_COUNTRY_FILTER : CANADA_COUNTRY_FILTER);
  return await findLocationByPlaceId(placeIds[0]?.place_id);
}

async function querySinglePosition(
  input: string,
  country: readonly string[] = US_AND_CANADA_COUNTRY_FILTER
) {

  const autocompleteService = await getAutoCompleteService();

  if (autocompleteService) {
    const inputUpper = input.toUpperCase();
    if ((/USA$|US$|, USA|, US/).test(inputUpper) && !country.includes('us')) {
      // added a condition to forcibly ignore the header being set to Canada when a US location is entered without interfering with other functions
      country = US_AND_CANADA_COUNTRY_FILTER;
    }

    const request = await createAutocompletionRequest(input, AutocompletePredictionRestrictionTypes.GEOCODE, country);
    const response = await autocompleteService.getPlacePredictions(request);

    if (response?.predictions) {
      return response.predictions;
    }
  }
  throw new Error('AutocompleteService could not be created.');
}

/**
 * Converts special characters, escape sequences, and specified characters in a string to space characters.
 * Removed characters include: 
 *  `\n`, `\r`, `\t`, `\b`, `\f`, `\v`, `'`, `"`, `\\`, Unicode escape sequences, `{`, `}`, `[`, `]`, `:`, `=`, `*`, `|`, `(`, `)`.
 * 
 * @param inputString The input string to convert.
 * @returns The converted string with removed characters replaced by spaces.
 */
export function convertSpecialCharactersToSpace(inputString: string) {
  return inputString.replace(/[\n\r\t\b\f\v'"\\]|\\u[\dA-Fa-f]{4}|[{}[\]:=*|()]/g, ' ');
}

/**
 * Removes non-alphanumeric chars from a string.
 * 
 * @param inputString  The input string to remove chars from.
 * @returns The string with non-alphanumeric chars removed.
 */
export function removeNonAlphanumericChars(inputString: string) {
  return inputString.replace(/\W/g, '');
}

/**
 * Removes HTML or XML tags from a string.
 * 
 * @param inputString  The input string to remove tags from.
 * @returns The string with HTML or XML tags removed.
 */
export function removeTagsFromString(inputString: string) {
  return inputString.replace(/<[^>]+>/g, '');
}

/**
 * Removes spaces in a string.
 * 
 * @param inputString The input string to process.
 * @returns The string with spaces removed.
 */
export function removeSpaces(inputString: string) {
  return inputString.replace(/\s/g, '');
}

/**
 * Replaces multiple consecutive spaces in a string with a single space.
 * 
 * @param inputString The input string to process.
 * @returns The string with multiple consecutive spaces replaced by a single space.
 */
export function replaceMultipleSpaces(inputString: string) {
  return inputString.replace(/ +/g, ' ');
}

/**
 * Sanitizes a string by removing HTML or XML tags, converting special characters to spaces,
 * and replacing multiple consecutive spaces with a single space.
 *
 * @param inputString The input string to sanitize.
 * @returns The sanitized string with tags removed, special characters converted to spaces,
 * and multiple consecutive spaces replaced by a single space.
 * Returns `null` if the input string is `null`.
 * Returns `undefined` if the input string is `undefined`.
 * Returns empty string if the input string is empty.
 */
export function sanitizeString(inputString: string | null) {
  if (inputString === undefined)
    return undefined;

  if (inputString === null)
    return null;

  if (inputString.length === 0)
    return '';

  let sanitizedString = removeTagsFromString(inputString);
  sanitizedString = convertSpecialCharactersToSpace(sanitizedString);
  sanitizedString = replaceMultipleSpaces(sanitizedString);
  return sanitizedString;
}

/**
 * Sanitizes a string by removing HTML or XML tags, non-alphanumeric chars and spaces.
 *
 * @param inputString The input string to sanitize.
 * @returns The sanitized string with tags, non-alphanumeric chars and spaces removed.
 * Returns `null` if the input string is `null`.
 * Returns `undefined` if the input string is `undefined`.
 * Returns empty string if the input string is empty.
 */
export function sanitizeTestid(inputString: string | null) {
  if (inputString === undefined)
    return undefined;

  if (inputString === null)
    return null;

  if (inputString.length === 0)
    return '';

  let sanitizedString = removeTagsFromString(inputString);
  sanitizedString = removeNonAlphanumericChars(sanitizedString);
  sanitizedString = removeSpaces(sanitizedString);
  return sanitizedString;
}

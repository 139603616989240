import { GTM_CLICK_FILTER_BEDS_MAP, GTM_CLICK_FILTER_BUY_MAP, GTM_CLICK_FILTER_COMMERCIAL_MAP, GTM_CLICK_FILTER_CONDO_MAP, 
  GTM_CLICK_FILTER_EXACT_NUMBER_BEDS_MAP, GTM_CLICK_FILTER_FARM_MAP, GTM_CLICK_FILTER_HOUSE_MAP, GTM_CLICK_FILTER_LAND_MAP, 
  GTM_CLICK_FILTER_PRICE_APPLY_MAP, GTM_CLICK_FILTER_RENT_MAP, GTM_CLICK_FILTER_SOLD_MAP, GTM_CLICK_FILTER_TOWNHOUSE_MAP, 
  GTM_CLICK_FILTER_HOUSE, GTM_CLICK_FILTER_TOWNHOUSE, GTM_CLICK_FILTER_CONDO, GTM_CLICK_FILTER_LAND, GTM_CLICK_FILTER_FARM,
  GTM_CLICK_FILTER_COMMERCIAL, GTM_CLICK_FILTER_BUY_AREA, GTM_CLICK_FILTER_RENT_AREA, GTM_CLICK_FILTER_SOLD_AREA, 
  GTM_CLICK_FILTER_ACTIVE_AREA, GTM_CLICK_FILTER_EXPIRED_AREA, GTM_CLICK_FILTER_HOUSE_AREA, GTM_CLICK_FILTER_TOWNHOUSE_AREA,
  GTM_CLICK_FILTER_CONDO_AREA, GTM_CLICK_FILTER_LAND_AREA, GTM_CLICK_FILTER_FARM_AREA, GTM_CLICK_FILTER_COMMERCIAL_AREA,
  GTM_CLICK_FILTER_PRICE_APPLY_AREA, GTM_CLICK_FILTER_EXACT_NUMBER_AREA, GTM_CLICK_FILTER_BEDS_AREA } from './events';

// Constants representing the quick filters on the top bar on area/map pages
export const BUY_FILTER_OPTION = 'buy';
export const RENT_FILTER_OPTION = 'rent';
export const SOLD_FILTER_OPTION = 'sold';
export const HOUSE_FILTER_OPTION = 'house';
export const TOWNHOUSE_FILTER_OPTION = 'townhouse';
export const CONDO_FILTER_OPTION = 'condo';
export const LAND_FILTER_OPTION = 'land';
export const FARM_FILTER_OPTION = 'farm';
export const COMMERCIAL_FILTER_OPTION = 'commercial';
export const PRICE_FILTER_OPTION = 'price';
export const EXACT_NUMBER_FILTER_OPTION = 'use_exact_number';
export const BEDS_FILTER_OPTION = 'beds';

export const FILTER_OPTION_TO_GTM_EVENT = {
  buy: GTM_CLICK_FILTER_BUY_MAP,
  rent: GTM_CLICK_FILTER_RENT_MAP,
  sold: GTM_CLICK_FILTER_SOLD_MAP,
  house: GTM_CLICK_FILTER_HOUSE_MAP,
  townhouse: GTM_CLICK_FILTER_TOWNHOUSE_MAP,
  condo: GTM_CLICK_FILTER_CONDO_MAP,
  land: GTM_CLICK_FILTER_LAND_MAP,
  farm: GTM_CLICK_FILTER_FARM_MAP,
  commercial: GTM_CLICK_FILTER_COMMERCIAL_MAP,
  price: GTM_CLICK_FILTER_PRICE_APPLY_MAP,
  use_exact_number: GTM_CLICK_FILTER_EXACT_NUMBER_BEDS_MAP,
  beds: GTM_CLICK_FILTER_BEDS_MAP,
};

export const AREA_PAGE_FILTER_OPTION_TO_GTM_EVENT = {
  buy: GTM_CLICK_FILTER_BUY_AREA,
  rent: GTM_CLICK_FILTER_RENT_AREA,
  sold: GTM_CLICK_FILTER_SOLD_AREA,
  active: GTM_CLICK_FILTER_ACTIVE_AREA,
  expired: GTM_CLICK_FILTER_EXPIRED_AREA,
  house: GTM_CLICK_FILTER_HOUSE_AREA,
  townhouse: GTM_CLICK_FILTER_TOWNHOUSE_AREA,
  condo: GTM_CLICK_FILTER_CONDO_AREA,
  land: GTM_CLICK_FILTER_LAND_AREA,
  farm: GTM_CLICK_FILTER_FARM_AREA,
  commercial: GTM_CLICK_FILTER_COMMERCIAL_AREA,
  price: GTM_CLICK_FILTER_PRICE_APPLY_AREA,
  use_exact_number: GTM_CLICK_FILTER_EXACT_NUMBER_AREA,
  beds: GTM_CLICK_FILTER_BEDS_AREA,
};

export const MORE_FILTERS_OPTIONS_TO_GTM_EVENT = {
  house: GTM_CLICK_FILTER_HOUSE,
  townhouse: GTM_CLICK_FILTER_TOWNHOUSE,
  condo: GTM_CLICK_FILTER_CONDO,
  land: GTM_CLICK_FILTER_LAND,
  farm: GTM_CLICK_FILTER_FARM,
  commercial: GTM_CLICK_FILTER_COMMERCIAL,
};

import { CountryCodeList } from 'types/countries';
import { EXP_MENU_LINK_LABEL_MY_SEARCHES, EXP_MENU_LINK_LABEL_SIGN_IN } from './exprealtyCA';

import type { CountryCode } from 'types/countries';
import type { SiteLanguage } from 'constants/locale';

export type ThemeColors = {
  primaryVeryLight: string;
  primaryLight: string;
  primary: string;
  primaryDark: string;
  primaryVeryDark: string;
  primaryBackground: string;
  primaryFontColor: string;
  primaryForSale: string;
  primarySold: string;
  primarySoldFocus: string;
  primaryFilters: string;
  primaryLinkColor: string;
  primaryFocusLinkColor: string;
  primaryInvertedBackground: string;
  primaryBorderColor: string;
  primaryFocusColor: string;
  primaryFocusBorderColor: string;
  primaryChart: string;
  secondaryBackground: string;
  secondaryBorderColor: string;
  secondaryLinkColor: string;
  secondaryHoverColor: string;
  secondaryFocusColor: string;
  secondaryFocusBorderColor: string;
  tertiaryBackground: string;
  tertiaryBackgroundLight: string;
  tertiaryBorderColor: string;
  tertiaryLinkColor: string;
  quaternaryLinkColor: string;
  quaternaryBorderColor: string;
  quaternaryBackgroundLight: string;
  transparentBackground: string;
  mapPinForSale: string;
  mapPinForSaleFocus: string;
  mapControlBackground: string;
  mapControlBackgroundHover: string;
  mapControlBackgroundActive: string;
  carouselArrowHover: string;
  carouselArrowFocus: string;
  notFound404PinHeadColor: string;
  notFOund404PinBodyColor: string;
}

interface ThemeFonts {
  heading: string;
  text: string;
  headingWeight: string;
  headingBoldWeight: string;
}

interface FooterImageData {
  src: string;
  alt: string;
}

export const SEARCH_OPTION_BUY = 'Buy';
export const SEARCH_OPTION_RENT = 'Rent';
export const SEARCH_OPTION_SOLD = 'Sold';
export const SEARCH_OPTION_AGENTS = 'Agents';
export const SEARCH_OPTION_HOME_APPRAISAL = 'HomeAppraisal';
export type SearchOptions = typeof SEARCH_OPTION_BUY | typeof SEARCH_OPTION_RENT | typeof SEARCH_OPTION_SOLD | typeof SEARCH_OPTION_AGENTS | typeof SEARCH_OPTION_HOME_APPRAISAL;

type FooterColItem = {
  label: string;
  link?: string;
  target?: string;
  isAnchor?: boolean;
};

export interface ThemeConfig {
  name: string;
  title: string;
  metaDescription: string;
  largeDesktopHeaderImage: string | undefined;
  desktopHeaderImage: string | undefined;
  mobileHeaderImage: string | undefined;
  smallMobileImage: string | undefined;
  ogSiteName: string;
  ogImage: string;
  ogImageAlt?: string;
  schemaUrl: string;
  schemaLogo: string;
  schemaLocation: {
    addressCountry: string;
    addressLocality: string;
    addressRegion: string;
    postalCode: string;
    streetAddress: string;
  };
  schemaFoundingDate: string;
  schemaFounders: {
      '@type': string;
      name: string;
  }[];
  faviconPath: string;
  colors: ThemeColors;
  fonts: ThemeFonts;
  decorations: {
    brushstrokes: boolean;
    boxShadow: string;
    boxShadowLight: string;
    areaPageLineWidth: string;
  };
  buttons: {
    borderRadius: string;
    borderRadiusSmall: string;
    borderRadiusMedium: string;
    borderRadiusLarge: string;
    borderRadiusModal: string;
    borderRadiusBanner: string;
  };
  icons: {
    borderRadius: string;
  };
  style_overrides?: {
    breadcrumbs?: {
      /** The margin top of the breadcrumbs on mobile. Default is `0`. */
      mobileMarginTop?: React.CSSProperties['marginTop'];
      /** The box shadow of the breadcrumbs. Default is `none`. */
      boxShadow?: React.CSSProperties['boxShadow'];
    };
    areaListingsPage?: {
      /** The font weight of the page title. Default is `normal`. */
      pageTitleFontWeight?: React.CSSProperties['fontWeight'];
    };
    icon?: {
      square?: {
        hoverBackground?: {
          /** The background color of the square icon when hovered on light mode. Default is `$color-dark-navy-bg`. */
          light?: React.CSSProperties['backgroundColor'];
          /** The background color of the square icon when hovered on dark mode. Default is `$color-white-10`. */
          dark?: React.CSSProperties['backgroundColor'];
        };
        focusBackground?: {
          /** The background color of the square icon when focused on light mode. Default is `$color-dark-navy-bg`. */
          light?: React.CSSProperties['backgroundColor'];
          /** The background color of the square icon when focused on dark mode. Default is `$color-white-10`. */
          dark?: React.CSSProperties['backgroundColor'];
        };
        
      };
    };
    locationUnavailable?: { 
      /** The img src url for the location unavailable image. Default is '/next/assets/images/listing-page/location-unavailable.svg' */
     imagePath?: string; // path to the svg
     /** The message at the bottom of the disclaimer. Default is 'Location info is unavailable for now, but we'll update it once we have it.'*/
     message?: string; // custom message...
    }
  };
  organization: {
    phoneNumber: (isOnPreConPage?: boolean) => string | null;
    email: (isOnPreConPage?: boolean) => string | null;
    address: (siteLocation?: CountryCodeList) => string | null;
    twitterUrl: string;
    facebookUrl: string;
    instagramUrl: string;
    linkedinUrl: string;
    youtubeUrl?: string;
    joinSectionUrl?: string;
  };
  menuLinks?: { // Deprecating as moving the structure into the /tenants folder
    label: typeof EXP_MENU_LINK_LABEL_SIGN_IN | typeof EXP_MENU_LINK_LABEL_MY_SEARCHES | string;
    link?: string;
    isEmber?: boolean;
    newTab?: boolean;
  }[];
  footerContent?: {
    column1?: FooterColItem[];
    column2?: FooterColItem[];
    column3?: FooterColItem[];
    bottom?: FooterColItem[][];
    joinUsLink?: string;
    footerImageData?: FooterImageData;
  };
  searchOptions: {
    type: SearchOptions;
    placeholder: (siteLocation?: CountryCode, isOnMobile?: boolean) => string;
  }[];
  contentBlockBuy: {
    image: string;
    title: string;
    copy: string;
    link: string;
  };
  contentBlockSell: {
    image: string;
    title: string;
    copy: string;
    link: string;
  };
  privacyPolicy: (language?: SiteLanguage) => string;
  termsOfUse: (language?: SiteLanguage) => string;
  keywords?: {
    label: string;
    link: string;
    type: string;
  }[];
}

/**
 * Generates a string with the Global CSS variables for the given theme
 * configuration. These variables are used to style the application
 * according to the tenant's theme settings.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/:root
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/var
 *
 * @param theme The theme configuration object containing the colors, fonts, and
 *  decorations settings for the tenant.
 * @returns A string of CSS variables to be applied globally. This must be
 *  inserted into the <head> of the document.
 */
export const generateThemeCSSVariables = (theme: ThemeConfig) => {
  let rootStyles = `
      --themes-colors-primary-very-light: ${theme.colors.primaryVeryLight};
      --themes-colors-primary-light: ${theme.colors.primaryLight};
      --themes-colors-primary: ${theme.colors.primary};
      --themes-colors-primary-dark: ${theme.colors.primaryDark};
      --themes-colors-primary-very-dark: ${theme.colors.primaryVeryDark};
      --themes-colors-primary-background: ${theme.colors.primaryBackground};
      --themes-colors-primary-font-color: ${theme.colors.primaryFontColor};
      --themes-colors-primary-for-sale: ${theme.colors.primaryForSale};
      --themes-colors-primary-sold: ${theme.colors.primarySold};
      --themes-colors-primary-sold-focus: ${theme.colors.primarySoldFocus};
      --themes-colors-primary-filters: ${theme.colors.primaryFilters};
      --themes-colors-primary-link-color: ${theme.colors.primaryLinkColor};
      --themes-colors-primary-focus-link-color: ${theme.colors.primaryFocusLinkColor};
      --themes-colors-primary-inverted-background: ${theme.colors.primaryInvertedBackground};
      --themes-colors-primary-border-color: ${theme.colors.primaryBorderColor};
      --themes-colors-primary-focus-color: ${theme.colors.primaryFocusColor};
      --themes-colors-primary-focus-border-color: ${theme.colors.primaryFocusBorderColor};
      --themes-colors-primary-chart: ${theme.colors.primaryChart};
      --themes-colors-secondary-background: ${theme.colors.secondaryBackground};
      --themes-colors-secondary-border-color: ${theme.colors.secondaryBorderColor};
      --themes-colors-secondary-link-color: ${theme.colors.secondaryLinkColor};
      --themes-colors-secondary-hover-color: ${theme.colors.secondaryHoverColor};
      --themes-colors-secondary-focus-color: ${theme.colors.secondaryFocusColor};
      --themes-colors-secondary-focus-border-color: ${theme.colors.secondaryFocusBorderColor};
      --themes-colors-tertiary-background: ${theme.colors.tertiaryBackground};
      --themes-colors-tertiary-background-light: ${theme.colors.tertiaryBackgroundLight};
      --themes-colors-tertiary-border-color: ${theme.colors.tertiaryBorderColor};
      --themes-colors-tertiary-link-color: ${theme.colors.tertiaryLinkColor};
      --themes-colors-quaternary-link-color: ${theme.colors.quaternaryLinkColor};
      --themes-colors-quaternary-border-color: ${theme.colors.quaternaryBorderColor};
      --themes-colors-quaternary-background-light: ${theme.colors.quaternaryBackgroundLight};
      --themes-colors-transparent-background: ${theme.colors.transparentBackground};
      --themes-colors-map-pin-for-sale: ${theme.colors.mapPinForSale};
      --themes-colors-map-pin-for-sale-focus: ${theme.colors.mapPinForSaleFocus};
      --themes-colors-map-control-background: ${theme.colors.mapControlBackground};
      --themes-colors-map-control-background-hover: ${theme.colors.mapControlBackgroundHover};
      --themes-colors-map-control-background-active: ${theme.colors.mapControlBackgroundActive};
      --themes-colors-carousel-arrow-hover: ${theme.colors.carouselArrowHover};
      --themes-colors-carousel-arrow-focus: ${theme.colors.carouselArrowFocus};
      --themes-buttons-border-radius: ${theme.buttons.borderRadius};
      --themes-buttons-border-radius-small: ${theme.buttons.borderRadiusSmall};
      --themes-buttons-border-radius-medium: ${theme.buttons.borderRadiusMedium};
      --themes-buttons-border-radius-large: ${theme.buttons.borderRadiusLarge};
      --themes-buttons-border-radius-modal: ${theme.buttons.borderRadiusModal};
      --themes-buttons-border-radius-banner: ${theme.buttons.borderRadiusBanner};
      --themes-icons-border-radius: ${theme.icons.borderRadius};
      --themes-box-shadow: ${theme.decorations.boxShadow};
      --themes-box-shadow-light: ${theme.decorations.boxShadowLight};
      --themes-area-page-line-width: ${theme.decorations.areaPageLineWidth};
      --themes-fonts-heading: ${theme.fonts.heading};
      --themes-fonts-text: ${theme.fonts.text};
      --themes-fonts-heading-weight: ${theme.fonts.headingWeight};
      --themes-fonts-heading-bold-weight: ${theme.fonts.headingBoldWeight};
      --themes-area-listings-page-title-font-weight: ${theme.style_overrides?.areaListingsPage?.pageTitleFontWeight || 'normal'};
      --themes-breadcrumb-mobile-margin: ${theme.style_overrides?.breadcrumbs?.mobileMarginTop || '0'};
      --themes-breadcrumb-box-shadow: ${theme.style_overrides?.breadcrumbs?.boxShadow || 'none'};
  `;

  //#region Icon Square Overrides
  if (theme.style_overrides?.icon?.square?.hoverBackground?.light) {
    rootStyles += `\n--icon-square-hover-light: ${theme.style_overrides?.icon?.square?.hoverBackground?.light};`;
  }

  if (theme.style_overrides?.icon?.square?.hoverBackground?.dark) {
    rootStyles += `\n--icon-square-hover-dark: ${theme.style_overrides?.icon?.square?.hoverBackground?.dark};`;
  }

  if (theme.style_overrides?.icon?.square?.focusBackground?.light) {
    rootStyles += `\n--icon-square-focus-light: ${theme.style_overrides?.icon?.square?.focusBackground?.light};`;
  }

  if (theme.style_overrides?.icon?.square?.focusBackground?.dark) {
    rootStyles += `\n--icon-square-focus-dark: ${theme.style_overrides?.icon?.square?.focusBackground?.dark};`;
  }
  //#endregion

  return `:root {
    ${rootStyles}
  }`;
};